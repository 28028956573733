export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UN_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NO_CONTENT_SUCCESS: 204,
  CONFLICT: 409,
  ACCEPTED: 202,
  UN_AVAILABLE: 503,
};

export const Languages = {
  ENGLISH: 'english',
  GAELIC: 'gaelic',
};

export const TIME_TO_IDLE = 180000;

export const ACTIVITY_EVENTS = [
  'click',
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'focus',
];

export const DataStoreAuthMessage = {
  heading: 'Please login to access the Data Store.',
  accessDeniedHeading: 'Access to this dataset is denied.',
  accessDeniedMessage:
    'The dataset you are trying to access is currently in the development stage and not yet fully available. We apologize for any inconvenience caused. Please bear with us while we work on making it accessible. Your understanding and patience are greatly appreciated.',
  radarContent:
    'You should be logged in to download data from the Radar Data Store. In case you do not have an account,',
  nwpContent:
    'You should be logged in to download data from the Nwp Data Store. In case you do not have an account,',
  cmdContent:
    'You should be logged in to download Climate Model Data. In case you do not have an account,',
  commonContent: 'you may create one easily using the Register option.',
};

export const nwpSurfaceParameterInfo = `These are forecast parameters at or close to
                                  the earth's surface. Some parameters are at 2m
                                  (temperature) or 10m (wind) above the ground.
                                  These correspond to the measurement heights
                                  for weather observations. Other parameters are
                                  aggregates over the full height of the
                                  atmosphere (eg. cloud height) but are still
                                  referred to as 'Surface'.`;

export const nwpAtmosphericParameterInfo = `These are forecast parameters at varying heights above the earth's surface.  These may be at chosen heights ('Altitude') or pressures ('isobaric') or from the native model 'hybrid' levels.`;

export const nwpForecastInfo = `A forecast run is defined by a model name, start date/time, an end time and a run length.  
  eg.  "Harmonie (2023.02.28)    1200     2100 (+57hr)"
  Model name:  This identifies the specific model.  Prirmary forecast model is 'Harmonie'.  Other models may be available later.`;

export const nwpForecastStartDateInfo = `This is given as yyyy.mm.dd for the start date of a model run.`;

export const nwpForecatsStartTimeInfo = `This is the hour for the start of the model run.  There may be multiple runs in a single day.`;

export const nwpForecastEndTime = `This is the hour for the end of the model run.  This is typically a number of days beyond the start date of the forecast run.`;

export const nwpForecastLength = `This is the duration of the model run.  This is typically 50-60 hours.`;

export const nwpEntireForecastCategory = `Entire: This forecast starts before and ends after your time range.  It is sufficient to satisfy all of your time range request.`;

export const nwpPartialBeginningForecastCategory = `Partial Beginning: This forecast finishes during your time range. It is sufficient to satisfy the beginning of your time range request.  Data is not available for the later part of your request.`;

export const nwpPartialMiddleForecastCategory = `Partial Middle: All of this forecast is within the range you specified.  It is  sufficient to satisfy the middle of your time range request.  Data is not available for both the earlier and later parts of your request.`;

export const nwpPartialEndForecastCategory = `Partial End: This forecast starts during your time range.  It is sufficient to satisfy the end of your time range request.  Data is not available for the earlier part of your request.`;

export const nwpPartialCoverageInfo = `Partial coverage will show number of hours missing.`;

export const nwpForecastControlOrEnsambleInfo = `We run a forecast Ensemble Prediction System (https://www.met.ie/irelands-first-high-resolution-ensemble-based-forecasting-system).  Outputs from the EPS allow us to improve accuracy of our forecast.  Using our EPS we produce three kinds of output:`;

export const nwpForecastControlInfo = `Control: These are direct outputs from the unperturbed forecast.  They give one view on how the weather may develop.`;

export const nwpForecastEpsAggregates = `EPS aggregates:  These are products derived from aggregation of the EPS members.  They include such things as event probability and quantiles.  These are available when you select the 'Control' forecast.`;

export const nwpForecastEnsambleInfo = `Ensemble member: These are direct outputs from the individual EPS members.  There is a limited number of parameters available from each member as compared to the control forecast.  These are typically used as input to an aggregation system rather than used in isolation.  You may choose an individual ensemble member by number.`;

export const nwpSurfaceParameterAltitudeLevelInfo = `Altitude levels: These parameters are at a specific level above the earth's surface.`;

export const nwpSurfaceParameterPressureLevelInfo = `Pressure levels: These parameters are at the level above the earth's surface where the pressure is the same (isobaric).  These represent a curved surface.`;

export const nwpSurfaceParameterModelLeveInfo = `Model levels: These parameters are at the model's native 'hybrid' levels.  These levels are contour-following near the earth's surface but level at the top of the atmosphere.   Model levels are numbered from the top of the atmosphere.  This means that the near-surface levels will have different numbers depending on the total number of levels within the model.`;

export const nrtMinDateTime = 3;

export const nwpForecastDefaultPageNumber = 1;

export const nwpForecastDefaultCatgory = 'ALL';

export const nwpForecastDefaultSortBy = 'latest';

export const nwpForecastDefaultLimit = 10;

export const cmdDefaultSelectedRange = [1981, 2100];

// eslint-disable-next-line @typescript-eslint/comma-dangle
export enum Category {
  PARTIAL_BEGINNING = 'PARTIAL_BEGINNING',
  PARTIAL_END = 'PARTIAL_END',
  PARTIAL_MIDDLE = 'PARTIAL_MIDDLE',
  ENTIRE = 'ENTIRE',
  ALL = 'ALL',
}

export const CategoryMapping = {
  PARTIAL_BEGINNING: 'Partial-Beginning',
  PARTIAL_END: 'Partial-End',
  PARTIAL_MIDDLE: 'Partial-Middle',
  ENTIRE: 'Entire',
  ALL: 'All',
};

export const colors = {
  appWhite: '#FFFFFF',
  blurredWhite: '#DBEAFE',
  blurredGrey: '#F9FAFB',
  appBlue: '#00305F',
  appTitle: '#111827',
  appCardContent: '#4B5563',
  appCardLightContent: '#6B7280',
  appErrorBackground: '#FEF2F2',
  appErrorContent: '#B91C1C',
  appWarningBackground: '#FFFBEB',
  appWarningContent: '#92400E',
  appInputPlaceHolder: '#9CA3AF',
  appInputLabel: '#374151',
  appInputBorder: '#d1d5db',
  appFormDivider: '#E5E7EB',
  appErrorIconColor: '#EF4444',
  appWarningIconColor: '#FBBF24',
  appFooterColor: '#F3F4F6',
  appCardBorder: '#D1D5DB',
  appSuccessLogo: '#D1FAE5',
  appTextGray: '#334155',
  appDarkGray: '#1F2937',
  appLightGray: '#CBD5E1',
  appButtonGray: '#E2E8F0',
  appButtonWhite: '#F8FAFC',
  appGray500: '#64748B',
  appGreen50: '#ECFDF5',
  appGreen700: '#047857',
  appGray400: '#94A3B8',
  appEmerald600: '#059669',
  appEmerald500: '#10B981',
  appNavDivider: '#003E7B',
  appSlate200: '#E2E8F0',
  appBlue50: '#EFF6FF',
  appBlue300: '#93C5FD',
  appGray44: '#707070',
  lightSilver: '#E1E1E1',
  darkBlue: '#030092',
  rose: '#BE123C',
  amber600: '#D97706',
  appSky100: '#E0F2FE',
  appRed600: '#D92D20',
  appRose700: '#B91C1C',
  appGrey: '#F0EDED',
  appGrey900: '#212121',
  appGrey600: '#4B5563',
  appWarningYellow: '#CA8A04',
  yellowRow: '#ffff00',
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const enum DialogType {
  LIMIT_EXCEEDED,
}

export const LimitReachedTitle = 'Service Unavailable';

export const maxNearRealTimeSelection = 15;

export const maxNwpSavedFiltersAllowed = 5;

export const maxNwpDateRangeSelection = 7;

export const generalRequestStatus = {
  'In Progress': 'In Progress'.toUpperCase(),
  Requested: 'Requested'.toUpperCase(),
  Queued: 'Queued'.toUpperCase(),
  Processing: 'Processing'.toUpperCase(),
  Completed: 'Completed'.toUpperCase(),
  Failed: 'Failed'.toUpperCase(),
  Cancelled: 'Cancelled'.toUpperCase(),
  Unavailable: 'Unavailable'.toUpperCase(),
  Expired: 'Expired'.toUpperCase(),
  All: 'All'.toUpperCase(),
};

export const monthMapping = {
  1: 'January',
  2: 'Febuary',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'Septemper',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const passwordValidationText =
  `Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character from the following list: [_ - / \ | { } [ ] , . < > ; : ' " ! * @ # $ % ^ & * ( ) ? = + ~.]`;

export const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_\-\/\\\|\}\{\[\]\.,<>;:\'\"\!\*@#\$%\^&\*\(\)\?\!=\+\~\`]).{8,}$/i;
export const InvalidRequestIdErrorMessage = 'Invalid Request Id';

export const breakpoints = {
  sm: '600',
  md: '768',
  lg: '992',
  xl: '1200',
};

export const UnAuthorizedRequestErrorText =
  'Unauthorized Request: You do not have permission to access this request.';

export const mediaQueries = (key: keyof typeof breakpoints) => (
  style: TemplateStringsArray | String
) => `@media (min-width: ${breakpoints[key]}px) { ${style} }`;

export const errorMessageStyle = {
  color: colors.appErrorContent,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '500',
};

export const warningMessageStyle = {
  color: colors.appWarningContent,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '500',
};

export const successMessageStyle = {
  color: colors.appGreen700,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '500',
};

export const FooterFacebookLink =
  'https://www.facebook.com/Met-%C3%89ireann-123518233000/';
export const FooterTwitterLink = 'https://twitter.com/MetEireann';
export const FooterLinkedinLink = 'https://ie.linkedin.com/company/met-ireann';
export const FooterYouTubeLink =
  'https://www.youtube.com/channel/UC67dRUD00xsU-hH93bqIQ_g';
export const FooterAppleAppStoreLink =
  'https://itunes.apple.com/ie/app/met-%C3%A9ireann-weather-ireland/id592109880';
export const FooterPlayStoreLink =
  'https://play.google.com/store/apps/details?id=net.fusio.meteireann&hl=en_IE';
export const FooterCookieInfoLink =
  'https://www.met.ie/about-us/cookie-information-notice';
export const FooterAsGaeilgeLink = 'https://www.met.ie/ga/';
export const FooterContactInfoLink = 'https://www.met.ie/about-us/contact-us';
export const FooterPrivacyInfoLink = 'https://www.met.ie/about-us/privacy';
export const FooterFAQLink = 'https://www.met.ie/about-us/faq';
export const FooterCopyRightsLink =
  'https://www.met.ie/about-us/copyright-notice';
export const FooterAccessibilityLink = 'https://www.met.ie/accessibility';
export const FooterHousingLink = 'https://www.housing.gov.ie/';
export const FooterMetLogoLink = 'https://www.met.ie/';
export const FooterWowLink = 'https://wow.met.ie/';

export const IrishMeta = {
  latitude: '53',
  longitude: '-8',
  maxlatitude: '55.4767103',
  minlatitude: '51.29974036',
  maxlongitude: '-5.848373279',
  minlongitude: '-10.77166062',
};

export const CmdRequestSample = `{
  "CMIP5":{
     "domain":"CMD",
     "dataset":"CMIP5",
     "parameters":{
        "temporalResolution":"",
        "experiment":"",
        "shortNames":[
           
        ],
        "regionalClimateModel":"",
        "earthSystemModel":"",
        "year":[],
        "month":[
           
        ],
        "geographicalExtent":{
           "north":90,
           "south":-90,
           "east":180,
           "west":-180
        }
     }
  },
  "CMIP6":{
     "domain":"CMD",
     "dataset":"CMIP6",
     "parameters":{
        "temporalResolution":"",
        "experiment":"",
        "shortNames":[
           
        ],
        "regionalClimateModel":"",
        "earthSystemModel":"",
        "year":[],
        "month":[
           
        ],
        "geographicalExtent":{
          "north":90,
          "south":-90,
          "east":180,
          "west":-180
        }
     }
  },
  "MERA":{
     "domain":"CMD",
     "dataset":"MERA",
     "parameters":{
        "temporalResolution":"",
        "shortNames":[
           
        ],
        "year":[],
        "month":[
           
        ],
        "day":[
           
        ],
        "geographicalExtent":{
          "north":90,
          "south":-90,
          "east":180,
          "west":-180
        }
     }
  },
  "TRANSLATE":{
     "domain":"CMD",
     "dataset":"TRANSLATE",
     "parameters":{
        "temporalResolution":"",
        "experiment":"",
        "shortNames":[
           
        ],
        "year":[],
        "month":[
           
        ],
        "day":[
           
        ],
        "geographicalExtent":{
          "north":90,
          "south":-90,
          "east":180,
          "west":-180
        }
     }
  }
}`;

// pretier-ignore // eslint-disable-next-line object-curly-spacing
export const PlaceToCoord = {
  Airports: {
    Abbeyfeale: {
      center: [52.381158, -9.320823],
      corners: [
        [52.46927942, -9.470648192],
        [52.47257225, -9.176361412],
        [52.29284214, -9.171593702],
        [52.28957048, -9.464688695],
      ],
    },
    Abbeyshrule: {
      center: [53.5888885, -7.653677],
      corners: [
        [53.67909471, -7.804299075],
        [53.67821756, -7.501585509],
        [53.49849314, -7.503695732],
        [53.49936458, -7.805127683],
      ],
    },
    Bantry: {
      center: [51.6761735, -9.490390501],
      corners: [
        [51.76410773, -9.638170482],
        [51.76778806, -9.348511193],
        [51.5880482, -9.343182277],
        [51.58439138, -9.63169805],
      ],
    },
    Belmullet: {
      center: [54.22717, -10.013349],
      corners: [
        [54.31430224, -10.17131738],
        [54.31944165, -9.864123285],
        [54.13982453, -9.856045055],
        [54.13471877, -10.16191028],
      ],
    },
    'Casement Aerodrome': {
      center: [53.30258767, -6.452348239],
      corners: [
        [53.39428199, -6.599386169],
        [53.39037699, -6.29881264],
        [53.21071602, -6.305939347],
        [53.21459571, -6.605254802],
      ],
    },
    Clonbullogue: {
      center: [53.251851, -7.123781001],
      corners: [
        [53.34272083, -7.272090686],
        [53.34051123, -6.97179907],
        [53.16079865, -6.976099073],
        [53.16299392, -7.275135174],
      ],
    },
    Connemara: {
      center: [53.230746, -9.4799695],
      corners: [
        [53.31862655, -9.633107157],
        [53.32235736, -9.333029212],
        [53.14266386, -9.327458266],
        [53.13895722, -9.626283364],
      ],
    },
    'Cork Airport': {
      center: [51.84242039, -8.490489805],
      corners: [
        [51.93161178, -8.636875046],
        [51.93282585, -8.346058287],
        [51.75304469, -8.344682553],
        [51.75183839, -8.634343334],
      ],
    },
    'Dublin Airport': {
      center: [53.42787785, -6.258812452],
      corners: [
        [53.51980875, -6.405855788],
        [53.51540849, -6.104425732],
        [53.33577039, -6.112402639],
        [53.34014208, -6.412565649],
      ],
    },
    'Galway Airport': {
      center: [53.30058119, -8.940832686],
      corners: [
        [53.38915671, -9.093122211],
        [53.39153072, -8.792493173],
        [53.21180797, -8.789172703],
        [53.20944935, -9.088542658],
      ],
    },
    'Inishbofin Airfield': {
      center: [53.61907171, -10.21112053],
      corners: [
        [53.70597302, -10.36716963],
        [53.711574, -10.06446107],
        [53.53196007, -10.05571153],
        [53.52639551, -10.35713988],
      ],
    },
    Inisheer: {
      center: [53.0640435, -9.5111055],
      corners: [
        [53.15189074, -9.663705328],
        [53.15569181, -9.364795207],
        [52.97599559, -9.359125873],
        [52.97221911, -9.65679559],
      ],
    },
    Inishmaan: {
      center: [53.093663, -9.5694465],
      corners: [
        [53.18143311, -9.722270045],
        [53.18538241, -9.423162282],
        [53.00569154, -9.417244182],
        [53.00176779, -9.71510949],
      ],
    },
    Inishmore: {
      center: [53.106243, -9.651864],
      corners: [
        [53.19390524, -9.804898455],
        [53.19806255, -9.505715327],
        [53.01837866, -9.499451136],
        [53.01424824, -9.797391081],
      ],
    },
    'Ireland West Airport Knock': {
      center: [53.91125412, -8.818251772],
      corners: [
        [53.99996783, -8.97251908],
        [54.00204867, -8.667497273],
        [53.82233938, -8.66463763],
        [53.82027213, -8.968353105],
      ],
    },
    'Kerry Airport': {
      center: [52.18117373, -9.525661903],
      corners: [
        [52.26904068, -9.675204552],
        [52.27283391, -9.382270034],
        [52.09311207, -9.376708005],
        [52.08934317, -9.668465021],
      ],
    },
    'Shannon Airport': {
      center: [52.69897185, -8.919708841],
      corners: [
        [52.78759478, -9.069831573],
        [52.78989739, -8.773364127],
        [52.61015553, -8.770193578],
        [52.60786776, -9.065446085],
      ],
    },
    'Sligo Airport': {
      center: [54.27971013, -8.598491152],
      corners: [
        [54.36869715, -8.753673621],
        [54.37022619, -8.445913052],
        [54.19052124, -8.443976757],
        [54.18900223, -8.750401177],
      ],
    },
    Trim: {
      center: [53.5667525, -6.7558205],
      corners: [
        [53.65807604, -6.904427695],
        [53.65492597, -6.601939011],
        [53.47524738, -6.607852705],
        [53.47837697, -6.90906259],
      ],
    },
    'Waterford Airport': {
      center: [52.18770334, -7.086090781],
      corners: [
        [52.27862352, -7.230770298],
        [52.27635091, -6.937725651],
        [52.09660783, -6.942000776],
        [52.09886586, -7.233866399],
      ],
    },
    'Weston Airport': {
      center: [53.35179022, -6.49176257],
      corners: [
        [53.44343675, -6.639053894],
        [53.43962877, -6.33812767],
        [53.25996569, -6.345102182],
        [53.26374898, -6.644766533],
      ],
    },
  },
  Counties: {
    CORK: {
      center: [51.91661446, -8.837424234],
      corners: [
        [50.92832953, -7.254667689],
        [52.90575868, -7.221184448],
        [52.88219886, -10.49039686],
        [50.90637606, -10.38344234],
      ],
    },
    GALWAY: {
      center: [53.36338905, -8.949886978],
      corners: [
        [52.37653099, -7.313000616],
        [54.3535002, -7.280440734],
        [54.32624966, -10.66322975],
        [52.35116307, -10.54286936],
      ],
    },
    MAYO: {
      center: [53.92360191, -9.383472777],
      corners: [
        [52.94268276, -7.715375491],
        [54.91958727, -7.701597115],
        [54.87960847, -11.13059637],
        [52.90548166, -10.98631057],
      ],
    },
    CARLOW: {
      center: [52.72931138, -6.823358413],
      corners: [
        [52.36260324, -6.245786538],
        [53.08129045, -6.216701916],
        [53.09319276, -7.410664102],
        [52.37420157, -7.420281247],
      ],
    },
    DUBLIN: {
      center: [53.39189826, -6.284612199],
      corners: [
        [53.0225541, -5.702989542],
        [53.74091597, -5.663979504],
        [53.75841764, -6.87635008],
        [53.03960593, -6.895129906],
      ],
    },
    KILDARE: {
      center: [53.19023968, -6.818744507],
      corners: [
        [52.82347845, -6.235178781],
        [53.54210536, -6.205421404],
        [53.55412688, -7.412313771],
        [52.83519156, -7.422064229],
      ],
    },
    KILKENNY: {
      center: [52.57374451, -7.23288747],
      corners: [
        [52.20901713, -6.653897278],
        [52.92786406, -6.631702078],
        [52.9356081, -7.821527277],
        [52.21656366, -7.824423344],
      ],
    },
    LAOIS: {
      center: [52.98360227, -7.355841385],
      corners: [
        [52.61945675, -6.770457404],
        [53.33828706, -6.749877987],
        [53.34482526, -7.951112603],
        [52.62582752, -7.951917632],
      ],
    },
    LONGFORD: {
      center: [53.71246617, -7.725395268],
      corners: [
        [53.35012182, -7.126946781],
        [54.06894707, -7.111936415],
        [54.07176079, -8.334173496],
        [53.35286295, -8.328524419],
      ],
    },
    LOUTH: {
      center: [53.90949208, -6.414089296],
      corners: [
        [53.54071954, -5.824344412],
        [54.25907918, -5.786682678],
        [54.2753679, -7.014272083],
        [53.55658725, -7.031058242],
      ],
    },
    MONAGHAN: {
      center: [54.15499976, -6.922963393],
      corners: [
        [53.78868295, -6.32529487],
        [54.50722769, -6.296034795],
        [54.51832502, -7.531235137],
        [53.79949259, -7.539288929],
      ],
    },
    SLIGO: {
      center: [54.1574082, -8.623479292],
      corners: [
        [53.79958283, -8.010977134],
        [54.51843889, -8.01116903],
        [54.51201437, -9.246591083],
        [53.79332488, -9.225179825],
      ],
    },
    WESTMEATH: {
      center: [53.52859408, -7.443703269],
      corners: [
        [53.16486198, -6.850215807],
        [53.88364723, -6.830582529],
        [53.88933379, -8.047405461],
        [53.17040211, -8.046609358],
      ],
    },
    WICKLOW: {
      center: [52.97858741, -6.372657173],
      corners: [
        [52.60970409, -5.795692697],
        [53.32816267, -5.758818005],
        [53.34467712, -6.95949256],
        [52.62579583, -6.976625644],
      ],
    },
    CAVAN: {
      center: [53.99489611, -7.35122535],
      corners: [
        [53.44749273, -6.45632666],
        [54.52540615, -6.415934678],
        [54.53547859, -8.269761222],
        [53.45717673, -8.262879323],
      ],
    },
    LEITRIM: {
      center: [54.13947306, -8.02137924],
      corners: [
        [53.68791157, -7.264180312],
        [54.58640919, -7.248079473],
        [54.58613297, -8.795141638],
        [53.68764428, -8.778115531],
      ],
    },
    LIMERICK: {
      center: [52.49601921, -8.749831002],
      corners: [
        [52.04896614, -8.013358313],
        [52.94778785, -8.013633529],
        [52.93830533, -9.501312502],
        [52.03978476, -9.471019439],
      ],
    },
    MEATH: {
      center: [53.63136339, -6.731774296],
      corners: [
        [53.1719064, -5.997427826],
        [54.06999649, -5.954445205],
        [54.08627446, -7.482167574],
        [53.18766168, -7.493057017],
      ],
    },
    OFFALY: {
      center: [53.20789688, -7.601056501],
      corners: [
        [52.75375639, -6.864528625],
        [53.65229046, -6.840525739],
        [53.65738337, -8.353243099],
        [52.75868644, -8.345928672],
      ],
    },
    ROSCOMMON: {
      center: [53.72299198, -8.245887844],
      corners: [
        [53.18223868, -7.345142942],
        [54.26052958, -7.328173625],
        [54.25672528, -9.169889486],
        [53.17858066, -9.140345147],
      ],
    },
    WATERFORD: {
      center: [52.21087424, -7.589711885],
      corners: [
        [51.75670182, -6.869578131],
        [52.65538962, -6.846536579],
        [52.66055847, -8.324609452],
        [51.76170693, -8.318123498],
      ],
    },
    WEXFORD: {
      center: [52.45950004, -6.572212448],
      corners: [
        [51.99922137, -5.858665755],
        [52.89740963, -5.814639704],
        [52.91545548, -7.300722645],
        [52.01669446, -7.31482212],
      ],
    },
    CLARE: {
      center: [52.79604541, -9.175149201],
      corners: [
        [52.08279912, -7.989083311],
        [53.52083991, -7.988717142],
        [53.49673771, -10.40030272],
        [52.05991297, -10.32249122],
      ],
    },
    DONEGAL: {
      center: [54.92223363, -7.908100026],
      corners: [
        [54.19627019, -6.683725134],
        [55.63340842, -6.635964763],
        [55.63534574, -9.176962206],
        [54.19810747, -9.135748243],
      ],
    },
    Kerry: {
      center: [52.05287342, -9.904306928],
      corners: [
        [51.34685408, -8.726392379],
        [52.78495545, -8.750107927],
        [52.74629292, -11.11958917],
        [51.31012865, -11.02113463],
      ],
    },
    TIPPERARY: {
      center: [52.64273185, -7.915128777],
      corners: [
        [51.91707908, -6.753562133],
        [53.35480846, -6.712011635],
        [53.35654573, -9.11546464],
        [51.91872881, -9.079476871],
      ],
    },
  },
  Lakes: {
    'Beltra Lough': {
      center: [53.92453573, -9.41636267],
      corners: [
        [53.83639519, -9.261446099],
        [54.01607243, -9.266876024],
        [54.01247022, -9.5719324],
        [53.83281649, -9.565196158],
      ],
    },
    'Carrowmore Lake': {
      center: [54.19248538, -9.783758873],
      corners: [
        [54.10483557, -9.627065627],
        [54.28447568, -9.634138424],
        [54.27992411, -9.941115509],
        [54.10031379, -9.932715931],
      ],
    },
    'Gardice Lough': {
      center: [54.04999409, -7.715464596],
      corners: [
        [53.9596792, -7.563703411],
        [54.13939126, -7.561816704],
        [54.14011613, -7.867884697],
        [53.96039932, -7.868453573],
      ],
    },
    'Lough Allen': {
      center: [54.12148365, -8.058445494],
      corners: [
        [54.0316026, -7.90568093],
        [54.2113175, -7.905271976],
        [54.21116847, -8.211871871],
        [54.03145455, -8.210957198],
      ],
    },
    'Lough Arrow': {
      center: [54.05774246, -8.321923349],
      corners: [
        [53.96819545, -8.168825772],
        [54.14791171, -8.169556071],
        [54.14709152, -8.475680142],
        [53.96738062, -8.47363141],
      ],
    },
    'Lough Boderg Lough Kilglass': {
      center: [53.84519466, -7.987795059],
      corners: [
        [53.75522095, -7.83618646],
        [53.93494367, -7.835483361],
        [53.93497469, -8.140054485],
        [53.75525176, -8.139455929],
      ],
    },
    'Lough Caragh': {
      center: [52.05474382, -9.862914759],
      corners: [
        [51.96710884, -9.713734286],
        [52.14680589, -9.720626203],
        [52.14218228, -10.01267925],
        [51.96251486, -10.0046193],
      ],
    },
    'Lough Carra': {
      center: [53.69649029, -9.247472591],
      corners: [
        [53.6081231, -9.093749976],
        [53.78781808, -9.098418923],
        [53.78465446, -9.40183961],
        [53.60498008, -9.395881854],
      ],
    },
    'Lough Conn': {
      center: [54.02751904, -9.237735761],
      corners: [
        [53.93914957, -9.082807984],
        [54.11883524, -9.087486676],
        [54.11568317, -9.393320975],
        [53.93601811, -9.38732741],
      ],
    },
    'Lough Currane': {
      center: [51.82872618, -10.12485338],
      corners: [
        [51.74141766, -9.975931683],
        [51.92109501, -9.983813403],
        [51.91583767, -10.2743511],
        [51.73619392, -10.26531733],
      ],
    },
    'Lough Cutra': {
      center: [53.03447237, -8.78208211],
      corners: [
        [52.94548992, -8.631685091],
        [53.12522691, -8.63431732],
        [53.12326024, -8.933098873],
        [52.94353596, -8.929227155],
      ],
    },
    'Lough Derg': {
      center: [52.95817928, -8.339861541],
      corners: [
        [52.73396866, -7.967947275],
        [53.18334435, -7.967613246],
        [53.18119944, -8.715632872],
        [52.73185824, -8.708252765],
      ],
    },
    'Lough Derravaragh': {
      center: [53.64907462, -7.359898713],
      corners: [
        [53.55831372, -7.210336445],
        [53.73802596, -7.206971607],
        [53.73964839, -7.510103999],
        [53.55992559, -7.512182803],
      ],
    },
    'Lough Ennell': {
      center: [53.46782493, -7.400209908],
      corners: [
        [53.37711371, -7.251193976],
        [53.55683316, -7.248024285],
        [53.55834991, -7.549861916],
        [53.3786206, -7.551759455],
      ],
    },
    'Lough Eske': {
      center: [54.70063195, -8.043549988],
      corners: [
        [54.61073901, -7.888656479],
        [54.79043647, -7.88816328],
        [54.79032463, -8.199129173],
        [54.61062791, -8.198251021],
      ],
    },
    'Lough Feeagh': {
      center: [53.94148686, -9.576886956],
      corners: [
        [53.85355627, -9.42157335],
        [54.03322117, -9.427696266],
        [54.02920994, -9.7328542],
        [53.84957123, -9.725424009],
      ],
    },
    'Lough Forbes': {
      center: [53.78103423, -7.879613561],
      corners: [
        [53.69092355, -7.728466986],
        [53.87064691, -7.727304292],
        [53.87095257, -8.031408442],
        [53.69122721, -8.031274523],
      ],
    },
    'Lough Funshinagh': {
      center: [53.5119025, -8.100965165],
      corners: [
        [53.42206736, -7.950305484],
        [53.60180081, -7.950094776],
        [53.60154534, -8.252262741],
        [53.42181355, -8.251197659],
      ],
    },
    'Lough Gara': {
      center: [53.93881336, -8.450808961],
      corners: [
        [53.8494286, -8.297872085],
        [54.02914678, -8.299154995],
        [54.02799993, -8.604400279],
        [53.84828924, -8.601808485],
      ],
    },
    'Lough Gill': {
      center: [54.25160081, -8.384123782],
      corners: [
        [54.16213651, -8.230175627],
        [54.34184628, -8.23117844],
        [54.34086524, -8.538738954],
        [54.16116191, -8.536402108],
      ],
    },
    'Lough Gowna': {
      center: [53.85778419, -7.56011324],
      corners: [
        [53.76727337, -7.409379994],
        [53.94698686, -7.406843932],
        [53.94810478, -7.711497731],
        [53.76838399, -7.712731301],
      ],
    },
    'Lough Graney': {
      center: [52.98503361, -8.658613773],
      corners: [
        [52.89589338, -8.508641235],
        [53.07563574, -8.51075694],
        [53.07398059, -8.809204306],
        [52.89424893, -8.805852611],
      ],
    },
    'Lough Inagh': {
      center: [53.51217185, -9.747606598],
      corners: [
        [53.42444779, -9.593532363],
        [53.60411138, -9.600288757],
        [53.59969013, -9.902317555],
        [53.42005528, -9.894287715],
      ],
    },
    'Lough Key': {
      center: [54.0007877, -8.249027062],
      corners: [
        [53.91114698, -8.096294395],
        [54.09086548, -8.096710069],
        [54.09023147, -8.402416688],
        [53.9105171, -8.400687094],
      ],
    },
    'Lough Leane': {
      center: [52.03024499, -9.510997873],
      corners: [
        [51.94215902, -9.362579087],
        [52.12188655, -9.36805419],
        [52.11813738, -9.660000266],
        [51.93843387, -9.653357948],
      ],
    },
    'Lough Lene': {
      center: [53.66323911, -7.228080544],
      corners: [
        [53.57231479, -7.078753188],
        [53.75202042, -7.074825695],
        [53.75397729, -7.378051393],
        [53.57425893, -7.380691901],
      ],
    },
    'Lough Macnean Upper': {
      center: [54.30297199, -7.943068473],
      corners: [
        [54.21294673, -7.789885923],
        [54.39265521, -7.788968797],
        [54.39280071, -8.096920191],
        [54.21309127, -8.09649898],
      ],
    },
    'Lough Melvin': {
      center: [54.43074578, -8.173754624],
      corners: [
        [54.34101553, -8.019593527],
        [54.52072138, -8.019679456],
        [54.52027663, -8.328590122],
        [54.3405737, -8.327155391],
      ],
    },
    'Lough Oughter': {
      center: [54.00926767, -7.468128961],
      corners: [
        [53.91864243, -7.317052672],
        [54.09834814, -7.314103848],
        [54.0997024, -7.619862459],
        [53.91998784, -7.621496864],
      ],
    },
    'Lough Owel': {
      center: [53.57351639, -7.392026728],
      corners: [
        [53.48279524, -7.242659988],
        [53.66251114, -7.239441774],
        [53.66405065, -7.542033587],
        [53.48432474, -7.543971562],
      ],
    },
    'Lough Ramor': {
      center: [53.81855829, -7.082346859],
      corners: [
        [53.72745338, -6.932790392],
        [53.90714634, -6.928214772],
        [53.90947721, -7.232552775],
        [53.72976905, -7.235829498],
      ],
    },
    'Lough Sheelin': {
      center: [53.80396428, -7.326998395],
      corners: [
        [53.71316269, -7.176961338],
        [53.89286878, -7.173434356],
        [53.89457795, -7.477684482],
        [53.71486072, -7.479913404],
      ],
    },
    'Muckno Lough': {
      center: [54.11232806, -6.702709238],
      corners: [
        [54.02075312, -6.552948574],
        [54.20041098, -6.546677542],
        [54.20371819, -6.853130672],
        [54.0240387, -6.858080166],
      ],
    },
    'Poullaphuca Reservoir': {
      center: [53.12930582, -6.520488022],
      corners: [
        [53.03751882, -6.374512711],
        [53.2171913, -6.367717392],
        [53.22091606, -6.667085985],
        [53.04121948, -6.672636003],
      ],
    },
    'Tacumshane Lake': {
      center: [52.19858873, -6.463517485],
      corners: [
        [52.10674503, -6.320682024],
        [52.286441, -6.31389441],
        [52.29026202, -6.606942285],
        [52.11054153, -6.612551223],
      ],
    },
    'Lough Corrib': {
      center: [53.48984427, -9.22051573],
      corners: [
        [53.26844966, -8.83941624],
        [53.71773611, -8.848336143],
        [53.70998058, -9.605593194],
        [53.26081948, -9.588717318],
      ],
    },
    'Lough Ree': {
      center: [53.54313843, -7.971154008],
      corners: [
        [53.3177883, -7.596105824],
        [53.7671085, -7.591806027],
        [53.76729193, -8.350196541],
        [53.31796877, -8.34650764],
      ],
    },
  },
  Mountains: {
    Baurregaum: {
      center: [52.206669, -9.8290245],
      corners: [
        [52.29869255, -9.686186128],
        [52.29414376, -9.97924306],
        [52.11899696, -9.679395203],
        [52.11447734, -9.971273609],
      ],
    },
    Beenoskee: {
      center: [52.2134895, -10.0767725],
      corners: [
        [52.30580786, -9.934434032],
        [52.3006427, -10.22749247],
        [52.12613702, -9.926641674],
        [52.12100499, -10.21852182],
      ],
    },
    Brandon: {
      center: [52.234776, -10.253868],
      corners: [
        [52.32730438, -10.11183715],
        [52.32169739, -10.4050017],
        [52.14765379, -10.1033239],
        [52.14208276, -10.39530924],
      ],
    },
    'Brandon Peak': {
      center: [52.215969, -10.2359935],
      corners: [
        [52.30847591, -10.09398383],
        [52.30291476, -10.38702776],
        [52.12882269, -10.08554823],
        [52.12329721, -10.37741418],
      ],
    },
    Carrauntoohil: {
      center: [51.9990275, -9.7422855],
      corners: [
        [52.09094528, -9.599920193],
        [52.08662422, -9.891628825],
        [51.91123559, -9.593524455],
        [51.90694219, -9.884068526],
      ],
    },
    Coomacarrea: {
      center: [51.9756515, -10.021084],
      corners: [
        [52.06790015, -9.879373016],
        [52.06288949, -10.17088239],
        [51.88821629, -9.871866723],
        [51.8832377, -10.16221387],
      ],
    },
    Coumfea: {
      center: [52.251671, -7.5520975],
      corners: [
        [52.34090498, -7.404444289],
        [52.34201977, -7.697940188],
        [52.16114276, -7.40684676],
        [52.16225039, -7.699158762],
      ],
    },
    'Croagh Patrick': {
      center: [53.759865, -9.658758],
      corners: [
        [53.85169833, -9.510387003],
        [53.84748856, -9.814210082],
        [53.67203459, -9.503952317],
        [53.66785225, -9.806482597],
      ],
    },
    Errigal: {
      center: [55.0414885, -8.121496],
      corners: [
        [55.13138725, -7.964963522],
        [55.13107393, -8.278572272],
        [54.95169963, -7.965119991],
        [54.95138839, -8.277328215],
      ],
    },
    Galtymore: {
      center: [52.3656615, -8.1782895],
      corners: [
        [52.45567528, -8.031520944],
        [52.45523086, -8.325781713],
        [52.27590692, -8.031393261],
        [52.27546535, -8.324462082],
      ],
    },
    Greenane: {
      center: [52.361965, -8.1050375],
      corners: [
        [52.45188783, -7.958132105],
        [52.45162602, -8.252369174],
        [52.27211937, -7.958301678],
        [52.27185924, -8.251347042],
      ],
    },
    Kippure: {
      center: [53.1777535, -6.33091],
      corners: [
        [53.26539001, -6.17758246],
        [53.26959456, -6.477261883],
        [53.08573691, -6.185182339],
        [53.08991424, -6.483613319],
      ],
    },
    Knockmealdown: {
      center: [52.2275125, -7.9151305],
      corners: [
        [52.31720139, -7.768286734],
        [52.31741256, -8.061631499],
        [52.13743022, -7.769220684],
        [52.13764003, -8.061383083],
      ],
    },
    Lugnaquilla: {
      center: [52.9668615, -6.4636885],
      corners: [
        [53.05468024, -6.31138572],
        [53.05853981, -6.609619351],
        [52.87500793, -6.318374254],
        [52.87884257, -6.615374676],
      ],
    },
    Mangerton: {
      center: [51.969887, -9.4838895],
      corners: [
        [52.06149567, -9.341080045],
        [52.05781675, -9.632636107],
        [51.88176429, -9.335724482],
        [51.87810892, -9.626117365],
      ],
    },
    'Mount Leinster': {
      center: [52.617855, -6.7794685],
      corners: [
        [52.70609573, -6.629024959],
        [52.70914812, -6.924913128],
        [52.52638629, -6.634628207],
        [52.52941902, -6.929307707],
      ],
    },
    Mullaghanattin: {
      center: [51.9333915, -9.834093],
      corners: [
        [52.0254178, -9.692123757],
        [52.02087315, -9.983390545],
        [51.84571438, -9.6853754],
        [51.84119881, -9.975482297],
      ],
    },
    Mullaghcleevaun: {
      center: [53.10334, -6.405784],
      corners: [
        [53.19107745, -6.252875826],
        [53.19508957, -6.552047334],
        [53.01141477, -6.260142221],
        [53.01540093, -6.55807062],
      ],
    },
    Mweelrea: {
      center: [53.6370355, -9.829875],
      corners: [
        [53.72907639, -9.682308593],
        [53.72443972, -9.985217774],
        [53.54942393, -9.675173627],
        [53.54481742, -9.976800005],
      ],
    },
    Nephin: {
      center: [54.01247, -9.3684665],
      corners: [
        [54.10394791, -9.218560897],
        [54.10046364, -9.524268482],
        [53.92427005, -9.213321218],
        [53.92080854, -9.517715402],
      ],
    },
    Sheeffry: {
      center: [53.6647875, -9.7213795],
      corners: [
        [53.7566965, -9.573477564],
        [53.75233312, -9.87660429],
        [53.57703521, -9.566797334],
        [53.57270022, -9.868638811],
      ],
    },
    Tonelagee: {
      center: [53.053982, -6.381621],
      corners: [
        [53.14169021, -6.228841835],
        [53.14576052, -6.527666708],
        [52.96202834, -6.236194997],
        [52.96607232, -6.533780461],
      ],
    },
  },
  Ports: {
    Baltimore: {
      center: [51.4822635, -9.3756465],
      corners: [
        [51.39398611, -9.229287708],
        [51.57374001, -9.23413101],
        [51.57035199, -9.522570798],
        [51.39061969, -9.516596484],
      ],
    },
    'Bear Island': {
      center: [51.6382335, -9.8990305],
      corners: [
        [51.55062669, -9.751168994],
        [51.73033296, -9.758106775],
        [51.72564631, -10.04746203],
        [51.54596995, -10.0393842],
      ],
    },
    Bunbeg: {
      center: [55.0681025, -8.319419],
      corners: [
        [54.97856877, -8.162498191],
        [55.15825489, -8.163227875],
        [55.15743091, -8.477041191],
        [54.97775024, -8.474908742],
      ],
    },
    Cahersiveen: {
      center: [51.948338, -10.230372],
      corners: [
        [51.8611713, -10.08084806],
        [52.04083327, -10.08918391],
        [52.03530603, -10.38047582],
        [51.85567943, -10.37098021],
      ],
    },
    'Castletown Bearhaven': {
      center: [51.651927, -9.9084685],
      corners: [
        [51.56433285, -9.760544],
        [51.7440378, -9.767522342],
        [51.73932698, -10.05696346],
        [51.55965211, -10.04884419],
      ],
    },
    'Clear Island': {
      center: [51.4395165, -9.504233],
      corners: [
        [51.35139989, -9.357767754],
        [51.53114596, -9.363109011],
        [51.52744349, -9.651262234],
        [51.34772102, -9.644793001],
      ],
    },
    Cork: {
      center: [51.8313185, -8.32632],
      corners: [
        [51.7417389, -8.180872519],
        [51.92152277, -8.181594147],
        [51.92071518, -8.472345145],
        [51.74093647, -8.470468189],
      ],
    },
    Dingle: {
      center: [52.1371445, -10.275125],
      corners: [
        [52.05004502, -10.1248746],
        [52.2296961, -10.13344482],
        [52.22404366, -10.42596162],
        [52.0444288, -10.41621896],
      ],
    },
    Dublin: {
      center: [53.3453195, -6.1979395],
      corners: [
        [53.25314002, -6.051940625],
        [53.43277424, -6.043733083],
        [53.43732346, -6.344568693],
        [53.25765973, -6.351515599],
      ],
    },
    'Dun Laoghaire': {
      center: [53.2955495, -6.1326655],
      corners: [
        [53.20329238, -5.98697574],
        [53.38292104, -5.978509997],
        [53.38763196, -6.278983615],
        [53.20797277, -6.286192649],
      ],
    },
    Galway: {
      center: [53.266937, -9.046861],
      corners: [
        [53.17829859, -8.895103065],
        [53.35801745, -8.89886461],
        [53.35537707, -9.199247132],
        [53.17567533, -9.194229193],
      ],
    },
    Greencastle: {
      center: [55.202887, -6.9816665],
      corners: [
        [55.1116515, -6.827250522],
        [55.29129657, -6.821958598],
        [55.29392764, -7.136789359],
        [55.11426513, -7.140667522],
      ],
    },
    'Hare Island': {
      center: [51.499554, -9.4235005],
      corners: [
        [51.41133762, -9.27699466],
        [51.59108769, -9.282029023],
        [51.587581, -9.570572363],
        [51.40785329, -9.564405953],
      ],
    },
    'Horse Island': {
      center: [51.515911, -9.475763],
      corners: [
        [51.42776125, -9.329104679],
        [51.60750708, -9.334347526],
        [51.60387085, -9.622987826],
        [51.42414821, -9.616611968],
      ],
    },
    Kilimer: {
      center: [52.614338, -9.381976],
      corners: [
        [52.5261082, -9.231827748],
        [52.7058272, -9.236882994],
        [52.70237128, -9.532728306],
        [52.52267454, -9.526464952],
      ],
    },
    Kilronan: {
      center: [53.122449, -9.6638455],
      corners: [
        [53.0346008, -9.511350686],
        [53.21428322, -9.517668305],
        [53.21009488, -9.81696249],
        [53.03043956, -9.809400519],
      ],
    },
    Rossaveel: {
      center: [53.267036, -9.5576145],
      corners: [
        [53.17905563, -9.404815713],
        [53.35874221, -9.410719061],
        [53.35481388, -9.711040978],
        [53.17515276, -9.703882247],
      ],
    },
    Rosslare: {
      center: [52.254575, -6.3339935],
      corners: [
        [52.16257615, -6.191252114],
        [52.342258, -6.183926795],
        [52.34640414, -6.477326007],
        [52.16669569, -6.483469085],
      ],
    },
    'Sherkin Island': {
      center: [51.4772815, -9.3988435],
      corners: [
        [51.3890332, -9.252456605],
        [51.56878566, -9.2573903],
        [51.56534075, -9.545795717],
        [51.38561025, -9.539731378],
      ],
    },
    Skull: {
      center: [51.525165, -9.544537],
      corners: [
        [51.43710262, -9.397717824],
        [51.61684301, -9.403233119],
        [51.61303689, -9.691922912],
        [51.43332077, -9.685274145],
      ],
    },
    Tarbert: {
      center: [52.5871315, -9.3600265],
      corners: [
        [52.49887268, -9.210015521],
        [52.67859397, -9.214976373],
        [52.67519416, -9.510640588],
        [52.49549476, -9.504473517],
      ],
    },
    'West Town': {
      center: [55.264749, -8.225606],
      corners: [
        [55.17509723, -8.068122383],
        [55.35477813, -8.068430528],
        [55.35419477, -8.383799653],
        [55.17451774, -8.382071436],
      ],
    },
  },
  'National Parks': {
    'Wicklow Mountains National Park': {
      center: [53.09398241, -6.37387613],
      corners: [
        [52.7854681, -6.904289544],
        [53.41446231, -6.888188039],
        [52.77135449, -5.867173621],
        [53.40002444, -5.835853441],
      ],
    },
    'Killarney National Park': {
      center: [52.00175489, -9.554783182],
      corners: [
        [52.30832702, -10.07881889],
        [51.67950648, -10.04988759],
        [52.32193246, -9.052541959],
        [51.69280942, -9.037884177],
      ],
    },
    'Glenveagh National Park': {
      center: [54.99305299, -8.062049694],
      corners: [
        [55.30597468, -8.613767625],
        [54.67709824, -8.604244933],
        [54.67764966, -7.51888432],
        [55.30653905, -7.511301892],
      ],
    },
    'Burren National Park': {
      center: [53.04219563, -8.984942946],
      corners: [
        [53.35117163, -9.517777332],
        [52.72227159, -9.495847723],
        [53.3599115, -8.466444435],
        [52.73081522, -8.459702217],
      ],
    },
    'Connemara National Park': {
      center: [53.53522929, -9.888665558],
      corners: [
        [53.83997195, -10.43426508],
        [53.21146586, -10.39847368],
        [53.85683615, -9.371049146],
        [53.22794964, -9.350874176],
      ],
    },
    'Wild Nephin National Park': {
      center: [54.06779716, -9.59627615],
      corners: [
        [54.37379385, -10.14688583],
        [53.74521957, -10.11470027],
        [54.388145, -9.069783658],
        [53.75924505, -9.053734698],
      ],
    },
  },
  'Pop Centre': {
    Arklow: {
      center: [52.79769074, -6.159942042],
      corners: [
        [52.7054753, -6.015832149],
        [52.88512226, -6.007637346],
        [52.88973447, -6.304662012],
        [52.71005776, -6.311636663],
      ],
    },
    Artane: {
      center: [53.38299778, -6.205136319],
      corners: [
        [53.29082632, -6.058995139],
        [53.47046016, -6.05080606],
        [53.47499341, -6.351909249],
        [53.29533016, -6.358834828],
      ],
    },
    Ashtown: {
      center: [53.37251058, -6.331642096],
      corners: [
        [53.28049195, -6.185260081],
        [53.46013915, -6.177606411],
        [53.46435241, -6.478655634],
        [53.28467788, -6.485046259],
      ],
    },
    Athlone: {
      center: [53.4239304, -7.940691307],
      corners: [
        [53.33389277, -7.790679862],
        [53.51362775, -7.789795185],
        [53.51377764, -8.091337295],
        [53.33404169, -8.090952886],
      ],
    },
    Athy: {
      center: [52.99183169, -6.985728356],
      corners: [
        [52.90061117, -6.839218226],
        [53.08032343, -6.83438937],
        [53.08287252, -7.132856507],
        [52.90314378, -7.136449322],
      ],
    },
    Balbriggan: {
      center: [53.60832006, -6.182082002],
      corners: [
        [53.51611692, -6.03522855],
        [53.69574139, -6.026870758],
        [53.7003461, -6.329575766],
        [53.52069168, -6.336652934],
      ],
    },
    Ballina: {
      center: [54.11701418, -9.156969228],
      corners: [
        [54.02854256, -9.001876133],
        [54.20823018, -9.006219413],
        [54.20528047, -9.312723419],
        [54.02561215, -9.307057947],
      ],
    },
    Ballinasloe: {
      center: [53.32876493, -8.226948981],
      corners: [
        [53.23908589, -8.076669856],
        [53.41882478, -8.076992778],
        [53.41825189, -8.377859029],
        [53.23851671, -8.376274262],
      ],
    },
    Ballincollig: {
      center: [51.88802311, -8.59490193],
      corners: [
        [51.79877836, -8.448741232],
        [51.97855584, -8.450535235],
        [51.97708243, -8.741642109],
        [51.79731438, -8.738689143],
      ],
    },
    Ballinteer: {
      center: [53.27461774, -6.253540859],
      corners: [
        [53.18250641, -6.107657685],
        [53.36214866, -6.0997053],
        [53.36655356, -6.400051771],
        [53.18688275, -6.406748681],
      ],
    },
    Ballyfermot: {
      center: [53.34224692, -6.353564263],
      corners: [
        [53.25025523, -6.207236466],
        [53.42990553, -6.199683782],
        [53.43406183, -6.50052247],
        [53.25438458, -6.506814335],
      ],
    },
    Blanchardstown: {
      center: [53.38680946, -6.37720736],
      corners: [
        [53.29484571, -6.230677793],
        [53.47449696, -6.223211702],
        [53.47859594, -6.524369048],
        [53.2989181, -6.530570897],
      ],
    },
    Blarney: {
      center: [51.93435668, -8.566953531],
      corners: [
        [51.8450781, -8.420697197],
        [52.02485484, -8.42238189],
        [52.02344976, -8.713790919],
        [51.84368203, -8.710944117],
      ],
    },
    Bray: {
      center: [53.20090639, -6.111083426],
      corners: [
        [53.10862511, -5.965755852],
        [53.28825427, -5.957230354],
        [53.29301381, -6.257035786],
        [53.11335383, -6.264311713],
      ],
    },
    Cabinteely: {
      center: [53.26495265, -6.159562955],
      corners: [
        [53.17272841, -6.013916679],
        [53.35236096, -6.005573486],
        [53.35700222, -6.305836477],
        [53.1773396, -6.312925179],
      ],
    },
    Cabra: {
      center: [53.36739667, -6.302343422],
      corners: [
        [53.27534273, -6.156042515],
        [53.45498714, -6.148267123],
        [53.45927409, -6.449275621],
        [53.27960187, -6.45578843],
      ],
    },
    Carlow: {
      center: [52.83650806, -6.934137863],
      corners: [
        [52.74522545, -6.788253812],
        [52.92493911, -6.783241328],
        [52.92761242, -7.080634232],
        [52.7478815, -7.08442208],
      ],
    },
    Carrickmacross: {
      center: [53.97797407, -6.718826539],
      corners: [
        [53.88642012, -6.569506134],
        [54.06608333, -6.563337367],
        [54.06934397, -6.868802365],
        [53.88965946, -6.873660291],
      ],
    },
    'Carrick-on-Suir': {
      center: [52.34765056, -7.41896758],
      corners: [
        [52.25695984, -7.273677713],
        [52.43671429, -7.270725699],
        [52.43816227, -7.564852682],
        [52.25839851, -7.566614227],
      ],
    },
    Carrigaline: {
      center: [51.81790542, -8.391492853],
      corners: [
        [51.72840634, -8.245959886],
        [51.90818986, -8.246940721],
        [51.90722115, -8.537603019],
        [51.72744382, -8.535467786],
      ],
    },
    Castlebar: {
      center: [53.85499889, -9.287932802],
      corners: [
        [53.76668914, -9.133542079],
        [53.9463769, -9.138409055],
        [53.94310414, -9.44297408],
        [53.76343772, -9.436805993],
      ],
    },
    Cavan: {
      center: [53.99060044, -7.362944978],
      corners: [
        [53.89984383, -7.212165036],
        [54.07954586, -7.208765704],
        [54.08116755, -7.514381337],
        [53.90145493, -7.516467835],
      ],
    },
    Celbridge: {
      center: [53.33990053, -6.538457821],
      corners: [
        [53.24813279, -6.391736321],
        [53.42780042, -6.384961217],
        [53.43148997, -6.685809863],
        [53.25179841, -6.691323884],
      ],
    },
    Clane: {
      center: [53.29378683, -6.687040602],
      corners: [
        [53.20220017, -6.540153695],
        [53.38188171, -6.534013971],
        [53.38519428, -6.834556474],
        [53.20549126, -6.839438269],
      ],
    },
    Clondalkin: {
      center: [53.32173765, -6.395214167],
      corners: [
        [53.22979661, -6.248864841],
        [53.40945158, -6.241492978],
        [53.4135017, -6.542193181],
        [53.23382046, -6.548305668],
      ],
    },
    Clonmel: {
      center: [52.35581891, -7.690251693],
      corners: [
        [52.26546078, -7.544379549],
        [52.44522378, -7.542527158],
        [52.44599579, -7.836719444],
        [52.26622783, -7.837380621],
      ],
    },
    Cork: {
      center: [51.89689095, -8.486304706],
      corners: [
        [51.80751116, -8.340329303],
        [51.98729072, -8.341690324],
        [51.98608613, -8.632859931],
        [51.80631427, -8.630339267],
      ],
    },
    Crumlin: {
      center: [53.3284815, -6.30509018],
      corners: [
        [53.23643147, -6.158914208],
        [53.41607736, -6.151161928],
        [53.42035524, -6.45189598],
        [53.24068161, -6.458388605],
      ],
    },
    Donabate: {
      center: [53.48533793, -6.15045633],
      corners: [
        [53.39309879, -6.004089892],
        [53.57272359, -5.995637773],
        [53.57740103, -6.297458326],
        [53.39774585, -6.30463933],
      ],
    },
    Dooradoyle: {
      center: [52.63858759, -8.653079729],
      corners: [
        [52.54943255, -8.504308328],
        [52.72918559, -8.50637982],
        [52.72755178, -8.802456595],
        [52.54780927, -8.799174173],
      ],
    },
    Drogheda: {
      center: [53.71785215, -6.356094875],
      corners: [
        [53.62585805, -6.208482364],
        [53.80549711, -6.200830648],
        [53.80966698, -6.50435217],
        [53.63000076, -6.510714319],
      ],
    },
    Dromcondra: {
      center: [53.37062349, -6.253763239],
      corners: [
        [53.27851086, -6.107557461],
        [53.4581502, -6.099576776],
        [53.46255997, -6.400600366],
        [53.28289203, -6.407318354],
      ],
    },
    Dublin: {
      center: [53.34944096, -6.260081893],
      corners: [
        [53.2573363, -6.113933566],
        [53.43697694, -6.105985898],
        [53.44136956, -6.406860779],
        [53.26170043, -6.41354733],
      ],
    },
    Dunboyne: {
      center: [53.41927076, -6.475887412],
      corners: [
        [53.32742614, -6.329033048],
        [53.5070857, -6.321973482],
        [53.51093709, -6.623375247],
        [53.33125253, -6.629167872],
      ],
    },
    Dundalk: {
      center: [53.99794729, -6.405953533],
      corners: [
        [53.90600996, -6.257256873],
        [54.08564532, -6.249736558],
        [54.08970306, -6.555306048],
        [53.91004119, -6.561514653],
      ],
    },
    Dungarvan: {
      center: [52.09367578, -7.620356128],
      corners: [
        [52.00323024, -7.475477015],
        [52.18299941, -7.473364569],
        [52.18394231, -7.765821774],
        [52.00416709, -7.766761154],
      ],
    },
    Dunshaughlin: {
      center: [53.51351896, -6.540254846],
      corners: [
        [53.42175138, -6.392939345],
        [53.60141386, -6.386126279],
        [53.60510708, -6.68820748],
        [53.42542061, -6.693746281],
      ],
    },
    Edenderry: {
      center: [53.34328592, -7.050587376],
      corners: [
        [53.2521434, -6.902749857],
        [53.43184893, -6.898126486],
        [53.43424591, -7.199056039],
        [53.25452482, -7.202417123],
      ],
    },
    Ennis: {
      center: [52.84742185, -8.988737694],
      corners: [
        [52.75869734, -8.838571282],
        [52.93843164, -8.84204193],
        [52.9359514, -9.139516879],
        [52.75623311, -9.134820685],
      ],
    },
    Enniscorthy: {
      center: [52.50219961, -6.566136447],
      corners: [
        [52.410475, -6.422119032],
        [52.59017077, -6.415670985],
        [52.5937511, -6.710753891],
        [52.41403229, -6.716001881],
      ],
    },
    Fermoy: {
      center: [52.13935856, -8.275564706],
      corners: [
        [52.04972128, -8.129217954],
        [52.22949615, -8.129739241],
        [52.22881132, -8.42249958],
        [52.04904085, -8.420802049],
      ],
    },
    Galway: {
      center: [53.29177906, -9.028592473],
      corners: [
        [53.20311795, -8.876783561],
        [53.38283694, -8.880471461],
        [53.38024181, -9.181030529],
        [53.20053964, -9.176084341],
      ],
    },
    Garryowen: {
      center: [52.65968277, -8.602160314],
      corners: [
        [52.57046403, -8.453420163],
        [52.75021777, -8.455284051],
        [52.74871092, -8.751506707],
        [52.5689669, -8.748430335],
      ],
    },
    Glasnevin: {
      center: [53.38283324, -6.276293855],
      corners: [
        [53.2907476, -6.129997782],
        [53.47038889, -6.122108176],
        [53.47474247, -6.423221768],
        [53.29507294, -6.429847694],
      ],
    },
    Gorey: {
      center: [52.67574153, -6.294311746],
      corners: [
        [52.58368876, -6.150309733],
        [52.76335362, -6.142703772],
        [52.76762228, -6.438919634],
        [52.58792992, -6.445313845],
      ],
    },
    Greystones: {
      center: [53.14911886, -6.079343055],
      corners: [
        [53.0568005, -5.934256089],
        [53.23642769, -5.925614948],
        [53.24126394, -6.225052837],
        [53.06160545, -6.232448348],
      ],
    },
    Howth: {
      center: [53.38584542, -6.064687345],
      corners: [
        [53.29350494, -5.918844093],
        [53.47312319, -5.910063115],
        [53.47801122, -6.211162253],
        [53.29836125, -6.218679919],
      ],
    },
    Kells: {
      center: [53.72683952, -6.87483441],
      corners: [
        [53.6354792, -6.726052319],
        [53.81516162, -6.720608824],
        [53.8180162, -7.0242622],
        [53.63831519, -7.028414298],
      ],
    },
    Kildare: {
      center: [53.15893267, -6.909569532],
      corners: [
        [53.06761832, -6.762660265],
        [53.24732045, -6.757481626],
        [53.25006687, -7.057102929],
        [53.07034696, -7.061033309],
      ],
    },
    Kilkenny: {
      center: [52.65415046, -7.244794976],
      corners: [
        [52.56324719, -7.098861734],
        [52.74298446, -7.095158482],
        [52.74487413, -7.391334186],
        [52.56512468, -7.393825503],
      ],
    },
    Killarney: {
      center: [52.06912434, -9.516470172],
      corners: [
        [51.98104678, -9.367910704],
        [52.16077272, -9.373414928],
        [52.15700801, -9.665614564],
        [51.9773062, -9.658940491],
      ],
    },
    Knocklyon: {
      center: [53.28218845, -6.316206262],
      corners: [
        [53.19015254, -6.170161475],
        [53.36980097, -6.162469522],
        [53.37404827, -6.462879152],
        [53.19437231, -6.469314899],
      ],
    },
    Leixlip: {
      center: [53.3638429, -6.490007289],
      corners: [
        [53.2720161, -6.343309964],
        [53.45167864, -6.336324832],
        [53.45549165, -6.637336003],
        [53.27580436, -6.643058357],
      ],
    },
    Letterkenny: {
      center: [54.95584389, -7.734281627],
      corners: [
        [54.86555921, -7.579102631],
        [55.04524455, -7.577220568],
        [55.04592907, -7.890157156],
        [54.86623921, -7.890646154],
      ],
    },
    Limerick: {
      center: [52.67053507, -8.630830673],
      corners: [
        [52.5813527, -8.481995579],
        [52.76110537, -8.483977711],
        [52.75952656, -8.780272384],
        [52.57978406, -8.777077018],
      ],
    },
    Longford: {
      center: [53.72763, -7.793260199],
      corners: [
        [53.63741035, -7.642489186],
        [53.81713384, -7.64096134],
        [53.81765839, -7.944677423],
        [53.63793148, -7.944912848],
      ],
    },
    Loughlinstown: {
      center: [53.24457264, -6.121328001],
      corners: [
        [53.15230284, -5.975832957],
        [53.3319318, -5.967336171],
        [53.33666821, -6.267449475],
        [53.15700856, -6.274693402],
      ],
    },
    Loughrea: {
      center: [53.19891457, -8.56609257],
      corners: [
        [53.10966178, -8.415564071],
        [53.28940001, -8.417306117],
        [53.28797342, -8.717247019],
        [53.10824443, -8.714253074],
      ],
    },
    Lucan: {
      center: [53.35720743, -6.449859106],
      corners: [
        [53.26533205, -6.303271435],
        [53.4449911, -6.296119281],
        [53.44890513, -6.597077869],
        [53.26922068, -6.60296784],
      ],
    },
    Lusk: {
      center: [53.52632743, -6.165881263],
      corners: [
        [53.43410616, -6.019342172],
        [53.61373139, -6.010942045],
        [53.61837226, -6.313057498],
        [53.43871688, -6.320183338],
      ],
    },
    Malahide: {
      center: [53.45092665, -6.150145256],
      corners: [
        [53.35868775, -6.003895696],
        [53.53831357, -5.995453369],
        [53.54298973, -6.297029063],
        [53.36333356, -6.304202897],
      ],
    },
    Mallow: {
      center: [52.13897334, -8.653913753],
      corners: [
        [52.04980798, -8.506814459],
        [52.22957623, -8.508859015],
        [52.22795118, -8.80160103],
        [52.04819335, -8.798380508],
      ],
    },
    Maynooth: {
      center: [53.3812929, -6.591860981],
      corners: [
        [53.28958957, -6.444883475],
        [53.46926057, -6.438322295],
        [53.47281722, -6.73947065],
        [53.29312315, -6.744767504],
      ],
    },
    Midleton: {
      center: [51.91371637, -8.172229926],
      corners: [
        [51.82394749, -8.026822487],
        [52.00372972, -8.026929818],
        [52.003303, -8.318217826],
        [51.8235235, -8.316949572],
      ],
    },
    Monaghan: {
      center: [54.24924278, -6.968307526],
      corners: [
        [54.157995, -6.81746639],
        [54.33766788, -6.812315556],
        [54.34030257, -7.11981521],
        [54.16061243, -7.123632948],
      ],
    },
    Monkstown: {
      center: [53.29370068, -6.153961631],
      corners: [
        [53.20146919, -6.008231581],
        [53.38110025, -5.999855749],
        [53.38575736, -6.300319997],
        [53.20609611, -6.307439199],
      ],
    },
    Mulhuddart: {
      center: [53.40168402, -6.399816715],
      corners: [
        [53.30974743, -6.253187604],
        [53.4894004, -6.245812461],
        [53.49344306, -6.547078538],
        [53.31376385, -6.553188257],
      ],
    },
    Mullingar: {
      center: [53.52593711, -7.338146139],
      corners: [
        [53.43514907, -7.189061091],
        [53.6148641, -7.185621126],
        [53.61653903, -7.487869454],
        [53.43681312, -7.490032886],
      ],
    },
    Naas: {
      center: [53.22056967, -6.659315936],
      corners: [
        [53.12894999, -6.51273587],
        [53.30863153, -6.506497528],
        [53.31201084, -6.806522196],
        [53.13230741, -6.81150815],
      ],
    },
    Navan: {
      center: [53.6554279, -6.686706091],
      corners: [
        [53.56383747, -6.538582999],
        [53.74350794, -6.532354976],
        [53.74683672, -6.835471931],
        [53.56714458, -6.840414458],
      ],
    },
    Nenagh: {
      center: [52.86429856, -8.197563308],
      corners: [
        [52.77457552, -8.048952021],
        [52.95432862, -8.049154746],
        [52.9538329, -8.34678833],
        [52.774083, -8.345358134],
      ],
    },
    'New Ross': {
      center: [52.39620922, -6.947188469],
      corners: [
        [52.30494478, -6.802716007],
        [52.48467284, -6.797841637],
        [52.48729812, -7.092257582],
        [52.30755319, -7.095938651],
      ],
    },
    Newbridge: {
      center: [53.18114059, -6.796558861],
      corners: [
        [53.08968825, -6.649816669],
        [53.26938168, -6.644161289],
        [53.27241357, -6.94392591],
        [53.0927005, -6.948331576],
      ],
    },
    Newcastle: {
      center: [52.66702401, -8.567976512],
      corners: [
        [52.57776239, -8.419280631],
        [52.75751672, -8.421004639],
        [52.75609528, -8.717278912],
        [52.57635011, -8.714341865],
      ],
    },
    Portarlington: {
      center: [53.16120864, -7.187758802],
      corners: [
        [53.07023506, -7.04024892],
        [53.2499539, -7.036231565],
        [53.25199977, -7.335893099],
        [53.07226769, -7.338661624],
      ],
    },
    Portlaoise: {
      center: [53.0328088, -7.298802056],
      corners: [
        [52.94197179, -7.151491772],
        [53.12170005, -7.147956552],
        [53.12346331, -7.446732069],
        [52.94372364, -7.449027831],
      ],
    },
    Portmarnock: {
      center: [53.42560603, -6.131607072],
      corners: [
        [53.33334527, -5.985483499],
        [53.51296982, -5.976971208],
        [53.5176913, -6.278363905],
        [53.3380361, -6.285609677],
      ],
    },
    Ratoath: {
      center: [53.50629365, -6.465681052],
      corners: [
        [53.41443553, -6.318552996],
        [53.59409149, -6.311426566],
        [53.59797299, -6.613445879],
        [53.41829181, -6.619298768],
      ],
    },
    Roscommon: {
      center: [53.62758615, -8.189091925],
      corners: [
        [53.53786382, -8.037834912],
        [53.7175938, -8.037996015],
        [53.71711464, -8.340991271],
        [53.53738778, -8.339545501],
      ],
    },
    Rush: {
      center: [53.52566979, -6.0911548],
      corners: [
        [53.43335848, -5.944782394],
        [53.61297545, -5.936066426],
        [53.61780529, -6.238164217],
        [53.43815694, -6.245606165],
      ],
    },
    Salthill: {
      center: [53.26256108, -9.070430967],
      corners: [
        [53.17395276, -8.918640325],
        [53.35367057, -8.922500163],
        [53.35097091, -9.222849622],
        [53.17127059, -9.217733757],
      ],
    },
    Sandymount: {
      center: [53.33055815, -6.215747015],
      corners: [
        [53.23840036, -6.069758794],
        [53.41803694, -6.061630663],
        [53.42254037, -6.362365023],
        [53.24287459, -6.369233581],
      ],
    },
    Shannon: {
      center: [52.71247414, -8.87307114],
      corners: [
        [52.62359903, -8.72360349],
        [52.80334239, -8.72658371],
        [52.80115615, -9.023146775],
        [52.62142688, -9.018950585],
      ],
    },
    Skerries: {
      center: [53.5805471, -6.107878929],
      corners: [
        [53.48825491, -5.96128362],
        [53.66787208, -5.952620241],
        [53.67266298, -6.255113375],
        [53.49301466, -6.262498481],
      ],
    },
    Sligo: {
      center: [54.27661014, -8.476090952],
      corners: [
        [54.18726407, -8.321851395],
        [54.36697168, -8.323254909],
        [54.3657554, -8.630998511],
        [54.18605575, -8.628258993],
      ],
    },
    Swords: {
      center: [53.45575034, -6.219748011],
      corners: [
        [53.36359527, -6.073329557],
        [53.54322843, -6.065179319],
        [53.54772899, -6.366800994],
        [53.3680666, -6.373682175],
      ],
    },
    Tallaght: {
      center: [53.28659245, -6.366594145],
      corners: [
        [53.19461732, -6.220425124],
        [53.3742706, -6.212943173],
        [53.37839106, -6.513391499],
        [53.19871108, -6.519616785],
      ],
    },
    Thurles: {
      center: [52.68010563, -7.804437071],
      corners: [
        [52.58989094, -7.657257581],
        [52.76964651, -7.655847661],
        [52.77013603, -7.952223654],
        [52.5903773, -7.952419388],
      ],
    },
    Tipperary: {
      center: [52.47472523, -8.154437383],
      corners: [
        [52.38494259, -8.007227308],
        [52.56470765, -8.007256819],
        [52.56432212, -8.302247261],
        [52.38455955, -8.301018144],
      ],
    },
    Tralee: {
      center: [52.27130713, -9.699921607],
      corners: [
        [52.18347197, -9.55032313],
        [52.36317706, -9.55660686],
        [52.35894559, -9.850111741],
        [52.17926766, -9.842644695],
      ],
    },
    Tramore: {
      center: [52.16527369, -7.14617154],
      corners: [
        [52.07425098, -7.002029807],
        [52.25399814, -6.998000468],
        [52.25612072, -7.290902051],
        [52.07635994, -7.293753834],
      ],
    },
    Tuam: {
      center: [53.51798522, -8.855631005],
      corners: [
        [53.42910863, -8.703369457],
        [53.60882833, -8.706352485],
        [53.60666326, -9.0085304],
        [53.42695764, -9.004271678],
      ],
    },
    Tullamore: {
      center: [53.27552852, -7.493393907],
      corners: [
        [53.18493224, -7.344844471],
        [53.36466113, -7.342090553],
        [53.36593908, -7.642572185],
        [53.18620191, -7.64406842],
      ],
    },
    Waterford: {
      center: [52.252729, -7.125599498],
      corners: [
        [52.16168117, -6.9812198],
        [52.3414245, -6.977093462],
        [52.34360077, -7.270570978],
        [52.16384347, -7.273513753],
      ],
    },
    Westport: {
      center: [53.80213459, -9.514357162],
      corners: [
        [53.7141174, -9.359692522],
        [53.89379124, -9.36551909],
        [53.8899458, -9.669670032],
        [53.71029703, -9.662547003],
      ],
    },
    Wexford: {
      center: [52.33691976, -6.463334328],
      corners: [
        [52.24507401, -6.320061296],
        [52.42476569, -6.313237204],
        [52.42859424, -6.607201488],
        [52.24887796, -6.612837326],
      ],
    },
    Wicklow: {
      center: [52.98082211, -6.044594982],
      corners: [
        [52.88846547, -5.900136826],
        [53.06809386, -5.891406617],
        [53.07300682, -6.189669704],
        [52.89334669, -6.197166783],
      ],
    },
    Youghal: {
      center: [51.95427495, -7.847167927],
      corners: [
        [51.86410627, -7.70227943],
        [52.04388485, -7.701086379],
        [52.04426372, -7.992638258],
        [51.86448271, -7.992667641],
      ],
    },
  },
};
